"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function init() {
        // JavaScript to be fired on all pages
        $('.nav-toggle').on('click', function () {
          $(this).next('nav').toggleClass('active');
        });
        $(window).on('load', function () {
          $('.gallery-modal-btn').on('click', function () {
            $('.gallery-modal img').attr('src', $(this).attr('data-src'));
            $(".gallery-modal").addClass('active');
          });
          $('.gallery-modal').on('click', function () {
            $(this).removeClass('active');
          });

          if ($('.hf-gallery').length) {
            var inst = new Glide('.gallery', {
              type: 'carousel',
              perView: 3,
              focusAt: '1',
              gap: 0,
              autoplay: 5000,
              peek: {
                before: 50,
                after: 50
              },
              breakpoints: {
                1024: {
                  focusAt: '0',
                  perView: 2
                },
                500: {
                  focusAt: '0',
                  perView: 1
                }
              }
            }).mount();
            $('.gallery .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                inst.go('=' + ind);
              }
            });
          }

          ;

          if ($('.temoignages').length) {
            var _inst = new Glide('.testimonial', {
              type: 'carousel',
              perView: 3,
              focusAt: '1',
              gap: 15,
              autoplay: 3000,
              breakpoints: {
                1024: {
                  focusAt: '0',
                  perView: 2
                },
                500: {
                  focusAt: '0',
                  perView: 1
                }
              }
            }).mount();

            $('.testimonial .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                _inst.go('=' + ind);
              }
            });
          }

          ;

          if ($('.formation-carousel').length) {
            var _inst2 = new Glide('.formation-carousel', {
              type: 'slider',
              perView: 3,
              focusAt: 'center',
              gap: 15,
              autoplay: 5000,
              peek: {
                before: 150,
                after: 150
              },
              breakpoints: {
                1024: {
                  focusAt: '0',
                  perView: 2,
                  peek: {
                    before: 0,
                    after: 0
                  }
                },
                500: {
                  focusAt: '0',
                  perView: 1,
                  peek: {
                    before: 0,
                    after: 0
                  }
                }
              }
            }).mount();

            $('.formation-carousel .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                _inst2.go('=' + ind);
              }
            });
          }

          ;

          if ($('.carousel-img-vid').length) {
            var _inst3 = new Glide('.carousel-img-vid .carousel', {
              type: 'carousel',
              perView: 3,
              focusAt: '1',
              gap: 0,
              autoplay: 5000,
              swipeThreshold: false,
              dragThreshold: false,
              keyboard: false,
              breakpoints: {
                1024: {
                  focusAt: '0',
                  perView: 2
                },
                500: {
                  focusAt: '0',
                  perView: 1
                }
              }
            }).mount();

            $('.carousel .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                _inst3.go('=' + ind);
              }
            });
          }

          ;

          if ($('.carousel-text').length) {
            var _inst4 = new Glide('.carousel-text .carousel', {
              type: 'carousel',
              perView: 1,
              focusAt: '0',
              gap: 0,
              autoplay: 5000
            }).mount();

            $('.carousel .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                _inst4.go('=' + ind);
              }
            });
            $('.carousel-text-toggle').on('click', function (e) {
              e.preventDefault();
              c = $(this).closest('.item');
              c.find('p').toggleClass('active');
            });
          }

          ;

          if ($('.slider-activity').length) {
            var _inst5 = new Glide('.slider-activity', {
              type: 'slider',
              perView: 3,
              focusAt: 'center',
              gap: 15,
              autoplay: 5000,
              peek: {
                before: 150,
                after: 150
              },
              breakpoints: {
                1024: {
                  focusAt: '0',
                  perView: 2,
                  peek: {
                    before: 0,
                    after: 0
                  }
                },
                500: {
                  focusAt: '0',
                  perView: 1,
                  peek: {
                    before: 0,
                    after: 0
                  }
                }
              }
            }).mount();

            $('.slider-activity .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                _inst5.go('=' + ind);
              }
            });
          }

          ;

          if ($('.partner-carousel').length) {
            var _inst6 = new Glide('.partner-carousel', {
              type: 'carousel',
              perView: 6,
              focusAt: '0',
              gap: 100,
              autoplay: 4000,
              breakpoints: {
                1024: {
                  focusAt: '0',
                  perView: 4
                },
                500: {
                  focusAt: '0',
                  perView: 3
                }
              }
            }).mount();

            $('.partner-carousel .glide__slides').on('click', function (e) {
              var ind = parseInt(e.target.getAttribute('data-index'));

              if (ind >= 0) {
                _inst6.go('=' + ind);
              }
            });
          }

          ;
        });
      },
      finalize: function finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // Template Carousel vidéo
        $('.video-slide video').on('play', function (e) {
          hideOverlay(e.target);
        });
        $('.video-slide video').on('pause', function (e) {
          showOverlay(e.target);
        });
        $('.glide__arrows').click(function () {
          var el = $('.glide__slide--active');
          el.find('video').removeAttr('viewing');
          el.find('.slide-inner .slide-overlay').css('display', 'block');
          el.find('video').get(0).pause();
        });

        function hideOverlay(elem) {
          elem.setAttribute('viewing', true);
          elem.parentNode.querySelector('.slide-overlay').style.display = 'none';
        }

        function showOverlay(elem) {
          elem.removeAttribute('viewing');
          elem.parentNode.querySelector('.slide-overlay').style.display = 'block';
        } // Template shortcode vidéo


        $('.video-title-overlay video').on('play', function (e) {
          hideOverlay(e.target);
        });
        $('.video-title-overlay video').on('pause', function (e) {
          showOverlay(e.target);
        });

        function hideOverlay(elem) {
          elem.setAttribute('viewing', true);
          elem.parentNode.querySelector('.overlay').style.display = 'none';
        }

        function showOverlay(elem) {
          elem.removeAttribute('viewing');
          elem.parentNode.querySelector('.overlay').style.display = 'flex';
        }
      }
    },
    // Home page
    'home': {
      init: function init() {// JavaScript to be fired on the home page
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function init() {// JavaScript to be fired on the about us page
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) namespace[func][funcname](args);
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      UTIL.fire('common', 'finalize');
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.